import { Fragment, useEffect } from 'react';
import { actionTypes } from '../../reducer';
import { useStateValue } from '../../StateProvider';
import { SetProgress } from '../../Utils';

import ListSelector from '../listSelector/ListSelector';

const InsuranceProviderSelector = ({
	question,
	inputName,
	redirectTo,
	progress
}) => {
	const [ { progressData }, dispatch ] = useStateValue();
	const providers = [
		'AAA / Auto Club',
		'AllState',
		'Farm Bureau',
		'Farmers',
		'GEICO',
		'Liberty Mutual',
		'Nationwide',
		'Progressive',
		'Safeco',
		'State Farm',
		'The Hartford',
		'USAA',
		'Other'
	];

	useEffect(() => {
		window.gtag('event', 'page_view', {
			page_title : question,
			page_path  : window.location.href
		});
	}, []);

	useEffect(
		() => {
			if (progress) {
				dispatch({
					type         : actionTypes.SET_PROGRESS,
					progressData : progress
				});
			}
		},
		[ progress ]
	);

	return (
		<Fragment>
			<ListSelector
				list={providers}
				question={question}
				inputName={inputName}
				redirectTo={redirectTo}
			/>
		</Fragment>
	);
};

export default InsuranceProviderSelector;
