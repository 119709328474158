import React, { Fragment } from 'react';
import StepContainer from '../../stepContainer/StepContainer';
import allstateLogo from '../../../img/allstate.png';
import '../Pages.css';

const Partners = () => {
	return (
		<Fragment>
			<StepContainer>
				<div className="legal-disclaimer">
					<h3>Partners</h3>

					<img src={allstateLogo} alt="AllState" />
				</div>
			</StepContainer>
		</Fragment>
	);
};

export default Partners;
