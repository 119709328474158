import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { actionTypes } from '../../reducer';
import { GetCarModels } from '../../Services';
import { useStateValue } from '../../StateProvider';
import { SetProgress } from '../../Utils';
import ButtonSelect from '../buttonSelect/ButtonSelect';
import CenteredContainer from '../centeredContainer/CenteredContainer';
import Spinner from '../spinner/Spinner';
import StepContainer from '../stepContainer/StepContainer';
import './ModelSelector.css';

const ModelSelector = ({ question, inputName, redirectTo, progress }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ { wizardData, progressData }, dispatch ] = useStateValue();
	const [ buttonsModels, setButtonsModels ] = useState([]);
	const [ otherModels, setOtherModels ] = useState([]);
	const history = useHistory();

	useEffect(() => {
		window.gtag('event', 'page_view', {
			page_title : question,
			page_path  : window.location.href
		});

		window.scrollTo({
			top      : 0,
			left     : 0,
			behavior : 'smooth'
		});
	}, []);

	useEffect(
		() => {
			if (progress) {
				dispatch({
					type         : actionTypes.SET_PROGRESS,
					progressData : progress
				});
			}
		},
		[ progress ]
	);

	useEffect(() => {
		let tempData = {
			...wizardData,
			[inputName] : null
		};

		dispatch({
			type       : actionTypes.SET_WIZARD_DATA,
			wizardData : tempData
		});

		getModels();
	}, []);

	useEffect(
		() => {
			if (wizardData && wizardData[inputName] && !isLoading) {
				history.push(redirectTo);
			}
		},
		[ wizardData ]
	);

	const getModels = async () => {
		if (!wizardData.year || !wizardData.make) {
			history.push('/');
		}

		let data = {
			Year : wizardData.year,
			Make : wizardData.make
		};

		const response = await GetCarModels(data);

		if (response.Success) {
			let responseModels = JSON.parse(response.Data);

			let tempButtonsModels = new Array();

			for (let i = 0; i < responseModels.length; i++) {
				tempButtonsModels.push(responseModels[i].name);
			}

			setButtonsModels(tempButtonsModels);

			/* temporarily disabled */
			// if (responseModels.length > 24) {
			// 	let tempButtonsModels = new Array();
			// 	let tempOtherModels = new Array();

			// 	for (let i = 0; i < 24; i++) {
			// 		tempButtonsModels.push(responseModels[i].name);
			// 	}

			// 	for (let i = 24; i < responseModels.length; i++) {
			// 		tempOtherModels.push(responseModels[i].name);
			// 	}

			// 	setButtonsModels(tempButtonsModels);
			// 	setOtherModels(tempOtherModels);
			// } else {
			// 	let tempButtonsModels = new Array();

			// 	for (let i = 0; i < responseModels.length; i++) {
			// 		tempButtonsModels.push(responseModels[i].name);
			// 	}

			// 	setButtonsModels(tempButtonsModels);
			// }
			/* end of temporarily disabled */
		}

		setIsLoading(false);
	};

	const handleSelection = (model) => {
		let tempData = {
			...wizardData,
			[inputName] : model
		};

		dispatch({
			type       : actionTypes.SET_WIZARD_DATA,
			wizardData : tempData
		});
	};

	const handleDropdownChange = (e) => {
		handleSelection(e.target.value);
	};

	return (
		<Fragment>
			{buttonsModels && buttonsModels.length > 0 && !isLoading ? (
				<Fragment>
					<StepContainer>
						<h3>{question}</h3>
						<div className="makes-buttons-container">
							{buttonsModels.map((model) => (
								<ButtonSelect
									key={model}
									onClick={() => handleSelection(model)}
								>
									<span>{model}</span>
								</ButtonSelect>
							))}
						</div>

						{otherModels &&
						otherModels.length > 0 && (
							<Fragment>
								<div className="input-container">
									<select
										name="other-makes"
										className="form-control form-control-lg mt-3"
										onChange={handleDropdownChange}
									>
										<option value="" key="other-models">
											Select Other Models
										</option>

										{otherModels.map((model) => (
											<option key={model} value={model}>
												{model}
											</option>
										))}
									</select>
								</div>
							</Fragment>
						)}
					</StepContainer>
				</Fragment>
			) : (
				<Fragment>
					<Spinner />
				</Fragment>
			)}
		</Fragment>
	);
};

export default ModelSelector;
