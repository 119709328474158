export const initialState = {
	brand        : null,
	wizardData   : {
		url : window.location.href
	},
	brand        : null,
	flowData     : null,
	campaignData : {},
	trackingData : {},
	progressData : 0,
	host         : window.location.origin,
	apiHost      : window.location.origin + '/Services/'
};

export const actionTypes = {
	SET_BRAND         : 'SET_BRAND',
	SET_WIZARD_DATA   : 'SET_WIZARD_DATA',
	SET_FLOW_DATA     : 'SET_FLOW_DATA',
	SET_CAMPAIGN_DATA : 'SET_CAMPAIGN_DATA',
	SET_TRACKING_DATA : 'SET_TRACKING_DATA',
	SET_PROGRESS      : 'SET_PROGRESS'
};

const reducer = (state, action) => {
	console.log(action);
	switch (action.type) {
		case actionTypes.SET_BRAND:
			return {
				...state,
				brand : action.brand
			};
		case actionTypes.SET_WIZARD_DATA:
			return {
				...state,
				wizardData : action.wizardData
			};
		case actionTypes.SET_FLOW_DATA:
			return {
				...state,
				flowData : action.flowData
			};
		case actionTypes.SET_CAMPAIGN_DATA:
			return {
				...state,
				campaignData : action.campaignData
			};
		case actionTypes.SET_TRACKING_DATA:
			return {
				...state,
				trackingData : action.trackingData
			};
		case actionTypes.SET_PROGRESS:
			return {
				...state,
				progressData : action.progressData
			};
		default:
			return state;
	}
};

export default reducer;
