import './ButtonSelect.css';

const ButtonSelect = ({ children, onClick }) => {
	const handleClick = () => {
		if (onClick) {
			onClick();
		}
	};

	return (
		<a className="btn-select" onClick={handleClick}>
			{children}
		</a>
	);
};

export default ButtonSelect;
