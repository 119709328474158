import { Fragment, useEffect } from 'react';
import StepContainer from '../stepContainer/StepContainer';
import './AreYouInsured.css';
import { useStateValue } from '../../StateProvider';
import { actionTypes } from '../../reducer';
import { useHistory } from 'react-router';
import ButtonSelect from '../buttonSelect/ButtonSelect';
import { SetProgress } from '../../Utils';

const AreYouInsured = ({ question, inputName, redirectTo, progress }) => {
	const [ { wizardData, progressData }, dispatch ] = useStateValue();
	const history = useHistory();

	useEffect(() => {
		window.gtag('event', 'page_view', {
			page_title : question,
			page_path  : window.location.href
		});

		window.scrollTo({
			top      : 0,
			left     : 0,
			behavior : 'smooth'
		});
	}, []);

	useEffect(
		() => {
			if (progress) {
				dispatch({
					type         : actionTypes.SET_PROGRESS,
					progressData : progress
				});
			}
		},
		[ progress ]
	);

	const handleSelection = (selection) => {
		let tempData = { ...wizardData };

		dispatch({
			type       : actionTypes.SET_WIZARD_DATA,
			wizardData : {
				...tempData,
				[inputName] : selection
			}
		});

		if (selection !== 'No') {
			history.push(redirectTo);
		} else {
			history.push('/gender');
		}
	};

	return (
		<Fragment>
			<StepContainer>
				<h3>{question}</h3>

				<div className="yes-no-container">
					<ButtonSelect onClick={() => handleSelection('Yes')}>
						<span>Yes</span>
					</ButtonSelect>

					<ButtonSelect onClick={() => handleSelection('No')}>
						<span>No</span>
					</ButtonSelect>
				</div>
			</StepContainer>
		</Fragment>
	);
};

export default AreYouInsured;
