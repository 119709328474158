import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { actionTypes } from '../../reducer';
import { useStateValue } from '../../StateProvider';
import StepContainer from '../stepContainer/StepContainer';
import providerLogos from '../../img/insurance-provider-logos.jpg';
import './ZipCode.css';
import Testimonial from '../testimonials/testimonial/Testimonial';
import johnImage from '../../img/JohnProfile.jpg';

const ZipCode = ({ question, inputName, redirectTo, progress }) => {
	const [
		{ wizardData, progressData, campaignData },
		dispatch
	] = useStateValue();
	const [ text, setText ] = useState('');
	const { zipCode } = useParams();
	const inputRef = useRef();
	const history = useHistory();

	useEffect(() => {
		window.scrollTo({
			top      : 0,
			left     : 0,
			behavior : 'smooth'
		});
	}, []);

	useEffect(
		() => {
			dispatch({
				type         : actionTypes.SET_PROGRESS,
				progressData : progress
			});
		},
		[ progress ]
	);

	useEffect(
		() => {
			if (zipCode) {
				history.push(redirectTo);
			}
		},
		[ zipCode ]
	);

	useEffect(() => {
		window.gtag('event', 'page_view', {
			page_title : question,
			page_path  : window.location.href
		});

		inputRef.current.focus();
	}, []);

	const handleChange = (e) => {
		setText(e.target.value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		let tempData = {
			...wizardData,
			[inputName] : text.trim() !== '' ? text.trim() : '00000'
		};

		dispatch({
			type       : actionTypes.SET_WIZARD_DATA,
			wizardData : tempData
		});

		history.push(redirectTo);
	};

	return (
		<Fragment>
			{!zipCode && (
				<Fragment>
					<StepContainer>
						<h3 className="mb-4">Get An Auto Insurance Quote:</h3>

						<h2>{question}</h2>

						<div className="inputs-container">
							<form autoComplete="off" onSubmit={handleSubmit}>
								<input
									ref={inputRef}
									type="text"
									className="form-control form-control-lg"
									name={inputName}
									onChange={handleChange}
								/>

								<div className="buttons-container">
									<button
										type="submit"
										className="btn btn-primary btn-lg btn-block"
									>
										<span>Start</span>
									</button>
								</div>
							</form>
						</div>
					</StepContainer>

					<Testimonial
						image={johnImage}
						name="John S."
						location={campaignData.testimonialLocation}
						text="This site helped me quickly find the best rate without having to make several phone calls and be on the phone for hours getting quotes from different insurance agents. It only took a minunte and I was able to save $62 per month!"
					/>

					<div className="provider-logos container-fluid mt-5">
						<img
							src={providerLogos}
							alt="Insurance Provider Logos"
						/>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default ZipCode;
