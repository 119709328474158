import { Fragment, useEffect } from 'react';
import { actionTypes } from '../../reducer';
import { useStateValue } from '../../StateProvider';
import { SetProgress } from '../../Utils';

import ListSelector from '../listSelector/ListSelector';

const CreditScoreSelector = ({ question, inputName, redirectTo, progress }) => {
	const providers = [ 'Excellent', 'Good', 'Not Good', 'Poor' ];
	const [ { progressData }, dispatch ] = useStateValue();

	useEffect(() => {
		window.gtag('event', 'page_view', {
			page_title : question,
			page_path  : window.location.href
		});
	}, []);

	useEffect(
		() => {
			if (progress) {
				dispatch({
					type         : actionTypes.SET_PROGRESS,
					progressData : progress
				});
			}
		},
		[ progress ]
	);

	return (
		<Fragment>
			<div className="credit-score-container">
				<ListSelector
					list={providers}
					question={question}
					inputName={inputName}
					redirectTo={redirectTo}
				/>
			</div>
		</Fragment>
	);
};

export default CreditScoreSelector;
